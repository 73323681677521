import axiosInstance from "@/services/axios";

export default {
    state: {
        data: {},
    },
    mutations: {
        setHotspot: (state, data) => {
            state.data = data
        }
    },
    actions: {
        setHotspot: (state, {id, code, q}) => {
            return new Promise((resolve, reject) => {
                let url = `/gallery_room/hotspot/${id}/?language=${code}`

                if (Object.keys(q).length) {
                    if (q.preview && q.preview === 'true') {
                        url += '&preview=true'
                    }
                }

                axiosInstance.get(url)
                    .then((res) => {
                        if (res.data) {
                            if (res.data.hotspot_content.length) {
                                state.commit('setHotspot', res.data)
                                return resolve(res.data)
                            }
                            reject({code: '204', message: 'No content'})
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            console.log(error.response.data.code, error.response.data.message)
                        } else {
                            console.log(error)
                        }
                        reject({code: '404', message: 'Not found'})
                    })
            })
        }
    },
    getters: {},
}