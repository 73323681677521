<template>
  <router-view/>
</template>

<script>
import {useStore} from "vuex";
import {computed, watch} from "vue";

export default {
  name: 'App',
  setup() {
    const store = useStore()
    const font = computed(() => (Object.hasOwnProperty.call(store.state.hotspot.data, 'hotspot_content')) ? store.state.hotspot.data.hotspot_content[0].language.fonts : null)

    const createStyleElement = (val) => {
      let style = document.createElement('style')

      // TODO: set font format -> const cssString = `@font-face {font-family: "loadedFont";src: url(${val}) format("truetype");}`
      const cssString = `@font-face {font-family: "loadedFont";src: url(${val});}`
      style.innerText = cssString
      return style
    }

    watch(font, (val) => {
      document.querySelector('head').appendChild(createStyleElement(val))

      const body = document.querySelector('body')
      body.style.setProperty('font-family', "loadedFont", 'important')
    })

    return {
      font
    }
  }
}
</script>

<style lang="scss">
@import "assets/scss/base";

#app {
  width: 100%;
  height: 100%;
}
</style>
