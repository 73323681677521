import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/hotspot/:id',
        name: 'hotspot',
        component: () => import("@/views/Hotspot"),
    },
    {
        path: '/hotspot/:id/:code',
        name: 'hotspot_width_code',
        component: () => import("@/views/Hotspot"),
    },
    {
        path: '/204',
        name: '204',
        component: () => import("@/components/errors/204")
    },
    {
        path: '/401',
        name: '401',
        component: () => import("@/components/errors/401")
    },
    {
        path: '/404',
        name: '404',
        component: () => import("@/components/errors/404")
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: () => import("@/components/errors/404")
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
