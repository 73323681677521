import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import mitt from "mitt";

const app = createApp(App)
const bus = mitt()

app.config.globalProperties.$BUS = bus

app.use(store)
app.use(router)

app.provide('$BUS', bus)

app.mount('#app')
